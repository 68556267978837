
import { IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput, IonToast, IonContent,
  IonCardHeader , IonCardTitle, IonLoading} from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';

export default {
  name: 'Login',
  components: { IonPage,
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonCardTitle,
    IonCardHeader,
    IonToast,
    IonLoading,
    IonContent
  },
  setup() {
    return {
      logIn,
      personAdd
    };
  },
  data() {
    return {
      user: {
        username: '',
        password: ''
      },
      loading: false,
      message:'',
      selectedColor: 'danger',
      error:false,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      await this.$store.dispatch("auth/login", this.user).then(
           () => {
             this.user = {username: '', password: '' },
            this.getAccount();
          },
          () => {
            this.loading = false;
            this.message = 'Incorrect email or password'
            this.error =true;
          }
      );
    },
    async getAccount(){
      await this.$store.dispatch("account").then(
          () =>{
            this.$router.replace("/home");
          },
          (error) =>{
            this.loading = false;
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                error.message ||
                error.toString();
            this.error =true;

          }
      );
    },

    clearPassword(){
      this.error =false;
      this.user.password ='';
    }
  }
}

